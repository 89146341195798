angular.module('fingerink')

    .directive('registerUser', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: 'web/public/register/directives/user/user.tpl.html',
            scope: {
                register: '=',
                parent: '='
            },
            controllerAs: 'controller',
            controller: 'registerUserCtrl'
        };
    })
    .controller('registerUserCtrl', function ($rootScope, $scope) {
        var that = this;

        that.user = $scope.register.user;

        that.domain = $rootScope.dominio;
        that.parent = $scope.parent;

        that.siguiente = function () {
            if (!that.user.name || !that.user.surname || !that.user.email || !that.user.password  || !that.privacidad || !that.user.repeatPassword   || that.user.password != that.user.repeatPassword  ) {
                if (that.user.password != that.user.repeatPassword) {
                    that.user.repeatPassword = "";
                }
                that.error = true;
                return;
            } else {
                $scope.parent.registerDraft();
            }
        };




    });
// !that.user.company.postalCode || !that.user.company.address || !that.user.company.city || !that.user.company.state || !that.user.company.nifCifIva 